import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CartIcon from '../../icons/cart';
import { useRecoilValue } from 'recoil';
import { cartCountState, cartState } from '../../atoms';

export default function Menu() {
  const [open, setOpen] = useState(false);
  const [subMenu, setSubMenu] = useState('');
  //const cartCount = useRecoilValue(cartCountState);
  const [cartCount, setCartCount] = useState(0);
  const cart = useRecoilValue(cartState);

  useEffect(() => {
    console.log(cart);
  }, [cart])

  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('showCart')) {
      setCartCount(6)
    }
  }, [])

  return (<div className='new-menu'>
    <div className='new-menu-icons'>
      <div className='hpCart'><Link to='/books'><CartIcon /> {cartCount > 0 && <div className='cartCount'><div className='cartCountNo'>{cartCount}</div></div>}</Link></div>
      <svg id="menu-icon" onClick={() => { setOpen(true) }} data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.71 122.99">
        <g id="" data-name="Layer 1">
          <line class="menu-stroke" x1="4" y1="4" x2="146.71" y2="4" />
          <line class="menu-stroke" x1="4" y1="118.99" x2="146.71" y2="118.99" />
          <line class="menu-stroke" x1="4" y1="61.5" x2="146.71" y2="61.5" />
        </g>
      </svg>
    </div>
    <div className={`new-menu-container ${open && 'new-menu-open'}`}>
      <div className='close-container'>
        <svg id="close-icon" onClick={() => { setOpen(false) }} data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140.75 140.75">
          <g id="uuid-76174fbb-fc2b-48cb-879b-3f755712c865" data-name="Layer 1">
            <line class="close-stroke" x1="7.55" y1="4" x2="136.75" y2="133.2" />
            <line class="close-stroke" x1="133.2" y1="7.55" x2="4" y2="136.75" />
          </g>
        </svg>
      </div>
      <ul className='new-menu' >
        <li>
          <Link to='/books' >Books</Link>
          <span onClick={() => { setSubMenu(subMenu !== 'books' ? 'books' : '') }}><Icon invert={subMenu === 'books' ? true : false} /></span>
        </li>
        {subMenu === 'books' && <>
          <li className='sub-menu'><Link to='/series/two'>Series Two</Link></li>
          <li className='sub-menu'><Link to='/series/one'>Series One</Link></li>
          <li className='sub-menu'><Link to='/subscriptions'>Subscriptions</Link></li>
        </>}
        <li>
          <Link to='/about'>About</Link>
          <span onClick={() => { setSubMenu(subMenu !== 'about' ? 'about' : '') }}><Icon invert={subMenu === 'about' ? true : false} /></span>
        </li>
        {subMenu === 'about' &&
          <li className='sub-menu'>
            <Link to='/design'>Design</Link>
          </li>}
        <li>
          <Link to='/contact'>Contact</Link>
          <span onClick={() => { setSubMenu(subMenu !== 'contact' ? 'contact' : '') }}><Icon invert={subMenu === 'contact' ? true : false} /></span>

        </li>
        {subMenu === 'contact' && <><li className='sub-menu'><Link to='/press'>Press</Link></li>
          <li className='sub-menu'><Link to='/where-to-buy'>Distributors & Stockists</Link></li>
          <li className='sub-menu'><Link to='/frequently-asked-questions'>FAQs</Link></li></>}
      </ul>
    </div>
  </div>)
}

function Icon({ invert }) {
  console.log(invert);
  return (
    <svg className={`down-icon ${invert && 'invert'}`} data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272.83 141.2">
      <g id="uuid-1483a340-a1eb-4df9-a6cd-bc9115ecac7a" data-name="Layer 1">
        <line class="down-stroke" x1="6" y1="6" x2="135.2" y2="135.2" />
        <line class="down-stroke" x1="266.83" y1="6" x2="137.64" y2="135.2" />
      </g>
    </svg>
  )
}