import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextControl } from '../InputControls';
import { makeRequest, validateEmail } from '../../utils';

export default function AboutPage() {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  document.title = `About | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `We seek to revive the legacy of Hanuman Books, the legendary and cult series of chapbooks that were printed in southern India and published out of the storied Chelsea Hotel in New York City between 1986 and 1993.`);


  const signup = async () => {
    if (!validateEmail(email)) {
      setSuccess(false);
      setMessage(`Please enter a valid email.`);
      return;
    }
    let data = await makeRequest(`newsletter?email=${email}`);
    if (data.ok) {
      setSuccess(true);
      setMessage(`Thanks! You've successfully signed up!`)
    } else {
      setSuccess(false);
      setMessage(`There was a problem signing you up.`)
    }
  };

  useEffect(() => {
    setMessage('');
  }, [email])

  return (
    <div className='pageContainer'>
      <div className='mainText'>

        <h1>About</h1>

        <p className='introGrafAbout'>Hanuman Editions is a publishing project reimagining the legacy of Hanuman Books, the legendary and cult series of chapbooks that were printed in southern India and published out of the storied Chelsea Hotel in New York City between 1986 and 1993. Our books are contemporary statements that channel the playful kitsch and small, object-like format of the original series. As Hanuman Editions, we seek to gather a trans-geographical, trans-disciplinary poetics through commissioned writings in the spirit of avant-garde foundations.</p>

        <h3 className='shrutsIntro'>From the Founding Editor, Shruti Belliappa</h3>

        <p>It was in 2012 when I first came across Hanuman Books, at a pavement bookstore on the South Bank in London; in particular, Allen Ginsberg’s <em>Your Reason and Blake’s System</em>, in which Ginsberg conveys the importance of William Blake to his own Beat context. In the chapbook, he writes, “There are similar revolutionary conditions now as in Blake’s time, similar social and emotional problems,” relating Blake’s transcendent insights to the fervour of the Sixties in America and subsequent so-called ‘disillusionment.’ Ginsberg’s observation is trenchant in reflecting on our own conditions of explosive global crises, as we continue to ask: how do we live together in an increasingly fractious, yet interdependent world?</p>

        <p>Much has transpired since Ginsberg’s time: the advent of the digital, new generational attitudes towards history and art, a redrawing of lines of confrontation with the mainstream and the dismantling of Western-centric knowledge realms—developments which, taken together, impel us to contemplate the ‘cutting edge’ within the public domain in fresh registers of aesthetic experience.</p>

        <p>In a pre-iPhone age, Hanuman books could be slipped into pockets and circulated among friends like ‘secret’ documents or samizdat of avant-garde subculture. How that subculture assumes form now, amid the volatility of a hyper-connected world, raises ever more questions, and also, possibilities. Reconceiving Hanuman Books  for our turbulent, globalized times, our publications will survey and produce a register of a culture that has morphed, splintered, and merged into many domains, from digital humanities to gaming, pornography, and artificial intelligence, because the truly subversive must work from within.</p>

        <p> We operate across borders and languages, publishing a set of six books every six months and inviting provocative and urgent voices of our epoch to speak in new tongues. We will also present genealogies of the avant-garde through revisiting the work of earlier artists and the republication of select entries in the original Hanuman catalog.</p>

        <p> We think with minds open at all times, looking to alter and expand our scope as we proceed. We recognize that a planetary-scale revolutionary cultural program, one free of the strictures of a single or small group of metropolises, would be a non-local phenomenon. As such, we imagine these books as a constellation, a set of disparate points that intimate the contours of a greater, emergent whole.</p>

        <p> Please sign up for our mailing list below to receive news of upcoming titles and events and follow us on social media: @hanumaneds (Twitter / Instagram).</p>



        {!success &&
          <div className='emailSignup'>
            <TextControl
              value={email}
              change={setEmail}
              placeholder={'Your email'}
            />
            <button className='signup-button' onClick={signup}>Submit</button>
          </div>
        }
        {message && <div className={`emailMessage ${success && 'emailSuccess'}`}>{message}</div>}
        <hr />
        <h2>About Us</h2>
        <p><strong>Shruti Belliappa</strong> is a writer, art historian and theorist engaged in collaborative art practices interrogating counter-cartographies, artificial life, and the technologies of belonging. At present, she is working on <em>Notes on Grief</em>, a book of nonfiction, and a novel, <em>Home Is The Place You Left</em>, in addition to her PhD project at Goldsmiths in London. She is the Founding Editor and co-publisher of Hanuman Editions.</p>
        <p><strong>Joshua Rothes</strong> is a writer, editor, designer, and translator. He is the publisher of Sublunary Editions, an independent press based in Seattle, Washington that specializes in brief books of adventurous writing covering some five centuries of literature and poetry. He is the editor of <em>The Collected Works of Kathleen Tankersley Young</em>, <em>The Collected Works of Emanuel Carnevali</em>, and <em>The Collected Poems of Marguerite Young</em>. As a writer, he has published <em>An Unspecific Dog</em> (punctum books, 2017), <em>We Later Cities</em> (Inside the Castle, 2020), <em>Six Novellas</em> (Sublunary Editions, 2022). He is the co-editor and co-publisher of Hanuman Editions.</p>
      </div>

    </div>
  )
}