import React, { useEffect, useState } from 'react';
import CartIcon from '../../icons/cart';
import { Link } from 'react-router-dom';


export default function BookPage() {

  document.title = `The Frontier Index by Raymond Pettibon & Mike Topp | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Guiding readers across the desiccated landscape of twenty-first century America in The Frontier Index  is the cult duo of Raymond Pettibon and Mike Topp, two of contemporary art and literature's wildest provocateurs in pen and ink.`);


  return (<div className='pageContainer n2-book'>
    <div className='n-book-images'>
      <div className='n-image n-primary-image'><img src='../../Frontier.png' /></div>
      <div className='n-image n-image-spine'><img src='../../raymikespine.png' /></div>
      <div className='n-image n-image-back'><img src='../../raymikeback.png' /></div>
      <div className='n-image n-image-2'><img src='../../raymike2.png' /></div>
      <div className='n-image n-image-3'><img src='../../raymike3.png' /></div>
    </div>

    <div className='n2-book-data'>
      <div className='n2-book-series'><Link to='/series/two'>Series Two</Link> | Spring 2024</div>
      <h1 className='n2-book-title'>The Frontier Index</h1>
      <div className='n2-book-author'>Raymond Pettibon & Mike Topp</div>

      <div className='n2-book-stats'>
        <div>ISBN 979-8-9904165-1-2</div>
        <div style={{ marginBottom: '6px' }}>160pp | $16.99 USD</div>
        <div style={{ marginTop: '12px' }}>
          <a rel='noreferrer' target='_blank' href='https://asterismbooks.com/product/the-frontier-index-raymond-pettibon-mike-topp'>Buy from Asterism (US/Canada)</a><br />
          <a rel='noreferrer' target='_blank' href='https://publicknowledgebooks.com/products/raymond-pettibon-mike-topp-the-frontier-index'>Buy from PKB (UK)</a><br />
          <a rel='noreferrer' target='_blank' href='https://www.ideabooks.nl/9798990416512-raymond-pettibon-mike-topp-the-frontier-index'>Buy from Idea Books (Europe/Asia)</a><br />
          <a rel='noreferrer' target='_blank' href='https://www.perimeterbooks.com/products/raymond-pettibon-mike-topp-the-frontier-index'>Buy from Perimeter (Australia/Oceania)</a>

        </div>
      </div>
      <div className='mainText'>

        <p>Guiding readers across the desiccated landscape of twenty-first century America in <em>The Frontier Index</em>  is the cult duo of Raymond Pettibon and Mike Topp, two of contemporary art and literature's wildest provocateurs in pen and ink. As they note, <em>The Frontier Index</em> is loosely based on Kenko’s 1332 <em>Essays In Idleness</em>, comprising a preface and 243 passages that can span a single line or a few pages. <em>Essays In Idleness</em> is similar to Sei Shōnagon’s <em>The Pillow Book</em>, from around 1000 AD, in that both books employ <em>zuihitsu</em>, a Japanese literary form that literally means “follow the brush,” where the creation of order depends on disorder. <em>Zuihitsu</em> demands as its starting point juxtapositions, fragments, contradictions, random materials and pieces of varying lengths. Oh yes, and wrestlers.</p>

        <p><strong>Raymond Pettibon</strong> is an American artist who lives and works in New York City. Pettibon came to prominence in the early 1980s southern California punk rock scene; over the past three decades, he has become widely renowned for his iconoclastic—read, “fuck off”—use of motifs pulled from the high (literature, art history, philosophy, religion) and low (politics, sports, sexuality) to engage in “a steady indictment of American culture as he has lived it over the past 60 years.”</p>

        <p><strong>Mike Topp</strong> was born in Washington, D.C. and currently lives in New York City unless he has died or moved. An emissary of enigma long before the era of the anon, Topp’s work moves between poetry, art criticism,  satire and sloganeering in whimsical koans with an indelibly deadpan élan. Recent collaborative books include <em>The Double Dream of Spring: A Peg Sluice Mystery with Sparrow</em>, and <em>Born On A Train</em> with Raymond Pettibon.</p>
      </div>
    </div>
  </div>)
}