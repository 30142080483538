import React, { useState } from 'react';
import { TextControl, TextAreaControl } from '../InputControls';
import { Link } from 'react-router-dom';
import { makeRequest, validateEmail } from '../../utils';

export default function ContactFaq() {

  document.title = `Frequently Asked Questions | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Contact information and F.A.Q.s for Hanuman Editions.`);

  return (
    <div className='pageContainer'>
      <div className='mainText'>
        <h1>F.A.Q.s</h1>

        <p className='bold'>Q: When can I order books? Are they available yet?</p>

        <p>Our books ship worldwide, and can be purchased via a Hanuman <Link to='/subscriptions'>subscription</Link>, or from one of our distribution partners or <Link to='/where-to-buy'>stockists</Link>. On special request for UK/EU customers and stockists, we can also ship directly from London, where our books are printed. Please email us at <a href='mailto:info@hanumaneditions.com'>info@hanumaneditions.com</a> for more information.</p>


        <p>Our books are currently available to the trade and libraries in the US and Canada via <a href='https://asterismbooks.com/publisher/hanuman-editions' target='_blank'>Asterism Books</a>, in the UK via <a href='https://publicknowledgebooks.com/collections/vendors?q=Hanuman%20Editions' target="_blank">Public Knowledge Books</a>, in Europe and Asia via <a href='https://www.ideabooks.nl/search?search=Hanuman+Editions' target='_blank'>Idea Books</a>, and in Australia via <a href='https://www.perimeterbooks.com/search?q=Hanuman+Editions' target='_blank'>Perimeter Books</a>.</p>

        <p className='bold'>Q: Can I be added to your press list?</p>

        <p>A: Sure! Just send us a message at <a href='mailto:press@hanumaneditions.com'>press@hanumaneditions.com</a> indicating what types of titles you typically review along with the outlets you work for or most commonly publish with.</p>

        <p className='bold'>Q: Will you be selling books from the original Hanuman Books series as well?</p>

        <p>A: We will not be selling books from Hanuman Books. They are out-of-print and highly collectible. We will, however, be reissuing select works from the Hanuman Books catalog over time, beginning this autumn.</p>

        <p className='bold'>Q: Will you offer subscriptions?</p>

        <p>A: Yes! You can subscribe to Hanuman Editions <Link to='/subscriptions'>here</Link>.</p>

        <p className='bold'>Q: Do you accept submissions?</p>

        <p>A: We are not accepting submissions at this time.</p>
      </div>
    </div>
  )
}