import React, { useEffect, useState } from 'react';
import CartIcon from '../../icons/cart';
import { Link } from 'react-router-dom';


export default function BookPage() {

  document.title = `Feminine Hijinx by Dodie Bellamy | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Already a member of The Feminist Writers’ Guild and one of the principal founders/proponents of the New Narrative movement, Dodie Bellamy published her first book with Hanuman Books in 1991.`);


  return (<div className='pageContainer n2-book'>
    <div className='n-book-images'>
      <div className='n-image n-primary-image'><img src='../../bellamy.png' /></div>
      <div className='n-image n-image-spine'><img src='../../bellamyspine.png' /></div>
      <div className='n-image n-image-back'><img src='../../SeriesOneBack.png' /></div>
      <div className='n-image n-image-2'><img src='../../bellamy3.png' /></div>
      <div className='n-image n-image-3'><img src='../../bellamy4.png' /></div>
    </div>

    <div className='n2-book-data'>
      <div className='n2-book-series'><Link to='/series/one'>Series One</Link> | Autumn 2023</div>
      <h1 className='n2-book-title'>Feminine Hijinx</h1>
      <div className='n2-book-author'>Dodie Bellamy</div>

      <div className='n2-book-stats'>
        <div>ISBN 979-8-9893780-2-9</div>
        <div style={{ marginBottom: '6px' }}>176pp | $12.99 USD</div>
        <div style={{ marginTop: '12px' }}>
          <a rel='noreferrer' target='_blank' href='https://asterismbooks.com/product/feminine-hijinx-dodie-bellamy'>Buy from Asterism (US/Canada)</a><br />
          <a rel='noreferrer' target='_blank' href='https://publicknowledgebooks.com/products/dodie-bellamy-feminine-hijinx-1'>Buy from PKB (UK)</a><br />
          <a rel='noreferrer' target='_blank' href='https://www.ideabooks.nl/9798989378029-dodie-bellamy-feminine-hijinx'>Buy from Idea Books (Europe/Asia)</a><br />
          <a rel='noreferrer' target='_blank' href='https://www.perimeterbooks.com/products/forthcoming-dodie-bellamy-feminine-hijinx'>Buy from Perimeter (Australia/Oceania)</a>
        </div>
      </div>
      <div className='mainText'>

        <p>Already a member of The Feminist Writers’ Guild and one of the principal founders/proponents of the New Narrative movement, Dodie Bellamy published her first book with Hanuman Books in 1991. <em>Feminine Hijinx</em> brings together two long pieces, "Complicity" and "The Debbies I Have Known", along with a new introduction by the author, written for this reissue.</p>
        <p><strong>Dodie Bellamy</strong> (b. 1951) is an American writer, journalist, and educator, most often associated with the New Narrative movement. She is the author of <em>The Letters of Mina Harker, Cunt-ups, Pink Steam, The TV Sutras</em>, and co-edited <em>Writers Who Love Too Much: New Narrative Writing 1977–1997</em> with Kevin Killian.</p>

      </div>
    </div>
  </div>)
}