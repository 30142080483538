import React, { useEffect, useState } from 'react';
import CartIcon from '../../icons/cart';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { cartState } from '../../atoms';
import { updateCartState } from '../../utils';

export default function BookPage() {
  const [cart, setCart] = useRecoilState(cartState);

  document.title = `Life Story by McKenzie Wark | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `From acclaimed theorist and trans icon McKenzie Wark, Life Story is a divulsion and revision of the author’s multiple forms.`);

  const addToCart = () => {
    let newCart = updateCartState(cart, {
      id: '9798989378067',
      priceId: 'price_1PTFJUFoScMjUSrmH8cmBjy9',
      title: 'Life Story',
      author: 'McKenzie Wark',
      image: 'LifeStory.png',
      price: 14.99,
      weight: 6,
    });
    setCart(newCart);
  }

  return (<div className='pageContainer n2-book'>
    <div className='n-book-images'>
      <div className='n-image n-primary-image'><img src='../../LifeStory.png' /></div>
      <div className='n-image n-image-spine'><img src='../../mckenziespine.png' /></div>
      <div className='n-image n-image-back'><img src='../../mckenzieback.png' /></div>
      <div className='n-image n-image-2'><img src='../../mckenzie2.png' /></div>
      <div className='n-image n-image-3'><img src='../../mckenzie3.png' /></div>
    </div>

    <div className='n2-book-data'>
      <div className='n2-book-series'><Link to='/series/two'>Series Two</Link> | Spring 2024</div>
      <h1 className='n2-book-title'>Life Story</h1>
      <div className='n2-book-author'>McKenzie Wark</div>

      <div className='n2-book-stats'>
        <div>ISBN 979-8-9893780-6-7</div>
        <div style={{ marginBottom: '6px' }}>152pp | $14.99 USD</div>
        {/*<div className='buyingOptions'>
          <button onClick={addToCart} className='cartButton'>Add to Cart </button>
        </div>*/}
        <div style={{ marginTop: '12px' }}>
          <a rel='noreferrer' target='_blank' href='https://asterismbooks.com/product/life-story-mckenzie-wark'>Buy from Asterism (US/Canada)</a><br />
          <a rel='noreferrer' target='_blank' href='https://publicknowledgebooks.com/products/mckenzie-wark-life-story'>Buy from PKB (UK)</a><br />
          <a rel='noreferrer' target='_blank' href='https://www.ideabooks.nl/9798989378067-mckenzie-wark-life-story'>Buy from Idea Books (Europe/Asia)</a><br />
          <a rel='noreferrer' target='_blank' href='https://www.perimeterbooks.com/products/mckenzie-wark-life-story'>Buy from Perimeter (Australia/Oceania)</a>

        </div>

      </div>
      <div className='mainText'>

        <p><em>“My idols are dead, and my enemies are in power. Domination co-opts language, making it almost unusable, other than by machines. We are to be ruled without even the semblance of style. I speak instead of what I have loved: writing, the city. In the end, after many diversions, this body, this world—and you.”</em></p>
        <p>From acclaimed theorist and trans icon McKenzie Wark, <em>Life Story</em> is a divulsion and revision of the author’s multiple forms. <em>Life Story</em> asks, "how to write not about love, but with love, in love, in form as well as content?" With an offering of lucidity amid disaster; a reinvention in the face of modernity’s unraveling. A tour of her selves, works, and worlds, <em>Life Story</em> is at once elegiac and mutinous, “an arc of history as built on an ontology of love.”</p>
        <p>In <em>Life Story</em>, <strong>McKenzie Wark</strong> plays with her future epitaph, creating a biographical form that eludes captured data-points. But for our purposes, let’s say that this Australian-born writer and scholar is renowned for her work on media theory, critical theory, the avant-gardes, and in recent years, raving and transsexual narratives. She is a professor of Media and Cultural Studies at The New School in New York City.</p>

      </div>
    </div>
  </div>)
}