import React, { useEffect, useState } from 'react';
import CartIcon from '../../icons/cart';
import { Link } from 'react-router-dom';


export default function BookPage() {

  document.title = `Bread and Water by Eileen Myles | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `First published by Hanuman Books in 1986, Bread and Water is the debut short story collection by the renowned poet Eileen Myles.`);


  return (<div className='pageContainer n2-book'>
    <div className='n-book-images'>
      <div className='n-image n-primary-image'><img src='../../myles.png' /></div>
      <div className='n-image n-image-spine'><img src='../../eileenspine.png' /></div>
      <div className='n-image n-image-back'><img src='../../SeriesOneBack.png' /></div>
      <div className='n-image n-image-2'><img src='../../eileen3.png' /></div>
      <div className='n-image n-image-3'><img src='../../eileen2.png' /></div>
    </div>

    <div className='n2-book-data'>
      <div className='n2-book-series'><Link to='/series/one'>Series One</Link> | Autumn 2023</div>
      <h1 className='n2-book-title'>Bread and Water</h1>
      <div className='n2-book-author'>Eileen Myles</div>

      <div className='n2-book-stats'>
        <div>ISBN 979-8-9893780-4-3</div>
        <div style={{ marginBottom: '6px' }}>168pp | $12.99 USD</div>
        <div style={{ marginTop: '12px' }}>
          <a rel='noreferrer' target='_blank' href='https://asterismbooks.com/product/bread-and-water-eileen-myles'>Buy from Asterism (US/Canada)</a><br />
          <a rel='noreferrer' target='_blank' href='https://publicknowledgebooks.com/products/eileen-myles-bread-and-water-1'>Buy from PKB (UK)</a><br />
          <a rel='noreferrer' target='_blank' href='https://www.ideabooks.nl/9798989378043-eileen-myles-bread-and-water'>Buy from Idea Books (Europe/Asia)</a><br />
          <a rel='noreferrer' target='_blank' href='https://www.perimeterbooks.com/products/forthcoming-eileen-myles-bread-and-water'>Buy from Perimeter (Australia/Oceania)</a>
        </div>
      </div>
      <div className='mainText'>

        <p>First published by Hanuman Books in 1986, <em>Bread and Water</em> is the debut short story collection by the renowned poet Eileen Myles, assembling six stories with an autobiographical flavor, of being a queer, working-class poet with a penchant for mischief. Stories include: "Light Warrior", "21, 22, 23…, "Merry Christmas Mr. Title", "Bath, Maine", "Bread and Water", and "Everybody Would Go Play Cards at Eddie and Nonie's".</p>
        <p><strong>Eileen Myles</strong> (b. 1949) is an American writer, poet, artist, teacher, and playwright. They are the author of more than twenty books, including <em>Sappho's Boat, Chelsea Girls, Afterglow</em>, and <em>For Now</em>.</p>

      </div>
    </div>
  </div>)
}